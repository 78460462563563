import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Main from "../Pages/Main";
import Redirect from "../Pages/Redirect";

const Routes = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/redirect/:phone",
      element: <Redirect />,
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
