import Afeganistao from "../../Assets/countryFlags/afeganistao.png";
import AfricaDoSul from "../../Assets/countryFlags/africa-do-sul.png";
import Albania from "../../Assets/countryFlags/albania.png";
import Alemanha from "../../Assets/countryFlags/alemanha.png";
import Andorra from "../../Assets/countryFlags/andorra.png";
import Angola from "../../Assets/countryFlags/angola.png";
import AntiguaEBarbuda from "../../Assets/countryFlags/antigua-e-barbuda.png";
import ArabiaSaudita from "../../Assets/countryFlags/arabia-saudita.png";
import Argelia from "../../Assets/countryFlags/argelia.png";
import Argentina from "../../Assets/countryFlags/argentina.png";
import Armenia from "../../Assets/countryFlags/armenia.png";
import Aruba from "../../Assets/countryFlags/aruba.png";
import Australia from "../../Assets/countryFlags/australia.png";
import Austria from "../../Assets/countryFlags/austria.png";
import Azerbaijao from "../../Assets/countryFlags/azerbaijao.png";
import Bahamas from "../../Assets/countryFlags/bahamas.png";
import Bahrein from "../../Assets/countryFlags/bahrein.png";
import Bangladesh from "../../Assets/countryFlags/bangladesh.png";
import Barbados from "../../Assets/countryFlags/barbados.png";
import Belgica from "../../Assets/countryFlags/belgica.png";
import Belize from "../../Assets/countryFlags/belize.png";
import Benim from "../../Assets/countryFlags/benim.png";
import Bermudas from "../../Assets/countryFlags/bermudas.png";
import Bielorrussia from "../../Assets/countryFlags/bielorrussia.png";
import Bolivia from "../../Assets/countryFlags/bolivia.png";
import BosniaEHerzegovina from "../../Assets/countryFlags/bosnia-e-herzegovina.png";
import Botsuana from "../../Assets/countryFlags/botsuana.png";
import Brasil from "../../Assets/countryFlags/brasil.png";
import Brunei from "../../Assets/countryFlags/brunei.png";
import Bulgaria from "../../Assets/countryFlags/bulgaria.png";
import BurkinaFaso from "../../Assets/countryFlags/burkina-faso.png";
import Burundi from "../../Assets/countryFlags/burundi.png";
import Butao from "../../Assets/countryFlags/butao.png";
import CaboVerde from "../../Assets/countryFlags/cabo-verde.png";
import Camaroes from "../../Assets/countryFlags/camaroes.png";
import Camboja from "../../Assets/countryFlags/camboja.png";
import Canada from "../../Assets/countryFlags/canada.png";
import Cazaquistao from "../../Assets/countryFlags/cazaquistao.png";
import Chade from "../../Assets/countryFlags/chade.png";
import Chile from "../../Assets/countryFlags/chile.png";
import China from "../../Assets/countryFlags/china.png";
import Chipre from "../../Assets/countryFlags/chipre.png";
import Colombia from "../../Assets/countryFlags/colombia.png";
import Comores from "../../Assets/countryFlags/comores.png";
import CoreiaDoNorte from "../../Assets/countryFlags/coreia-do-norte.png";
import CoreiaDoSul from "../../Assets/countryFlags/coreia-do-sul.png";
import CostaDoMarfim from "../../Assets/countryFlags/costa-do-marfim.png";
import CostaRica from "../../Assets/countryFlags/costa-rica.png";
import Croacia from "../../Assets/countryFlags/croacia.png";
import Cuba from "../../Assets/countryFlags/cuba.png";
import Dinamarca from "../../Assets/countryFlags/dinamarca.png";
import Djibuti from "../../Assets/countryFlags/djibuti.png";
import Dominica from "../../Assets/countryFlags/dominica.png";
import Egito from "../../Assets/countryFlags/egito.png";
import ElSalvador from "../../Assets/countryFlags/el-salvador.png";
import EmiradosArabesUnidos from "../../Assets/countryFlags/emirados-arabes-unidos.png";
import Equador from "../../Assets/countryFlags/equador.png";
import Eslovaquia from "../../Assets/countryFlags/eslovaquia.png";
import Eslovenia from "../../Assets/countryFlags/eslovenia.png";
import Espanha from "../../Assets/countryFlags/espanha.png";
import EstadosUnidos from "../../Assets/countryFlags/estados-unidos.png";
import Estonia from "../../Assets/countryFlags/estonia.png";
import Etiopia from "../../Assets/countryFlags/etiopia.png";
import Fiji from "../../Assets/countryFlags/fiji.png";
import Filipinas from "../../Assets/countryFlags/filipinas.png";
import Finlandia from "../../Assets/countryFlags/finlandia.png";
import Franca from "../../Assets/countryFlags/franca.png";
import Gabao from "../../Assets/countryFlags/gabao.png";
import Gambia from "../../Assets/countryFlags/gambia.png";
import Gana from "../../Assets/countryFlags/gana.png";
import Georgia from "../../Assets/countryFlags/georgia.png";
import Granada from "../../Assets/countryFlags/granada.png";
import Grecia from "../../Assets/countryFlags/grecia.png";
import Guatemala from "../../Assets/countryFlags/guatemala.png";
import Guiana from "../../Assets/countryFlags/guiana.png";
import GuineBissau from "../../Assets/countryFlags/guine-bissau.png";
import GuineEquatorial from "../../Assets/countryFlags/guine-equatorial.png";
import Guine from "../../Assets/countryFlags/guine.png";
import Haiti from "../../Assets/countryFlags/haiti.png";
import Honduras from "../../Assets/countryFlags/honduras.png";
import HongKong from "../../Assets/countryFlags/hong-kong.png";
import Hungria from "../../Assets/countryFlags/hungria.png";
import Iemen from "../../Assets/countryFlags/iemen.png";
import IlhasCayman from "../../Assets/countryFlags/ilhas-cayman.png";
import IlhasFaroe from "../../Assets/countryFlags/ilhas-faroe.png";
import India from "../../Assets/countryFlags/india.png";
import Indonesia from "../../Assets/countryFlags/indonesia.png";
import Ira from "../../Assets/countryFlags/ira.png";
import Iraque from "../../Assets/countryFlags/iraque.png";
import Irlanda from "../../Assets/countryFlags/irlanda.png";
import Islandia from "../../Assets/countryFlags/islandia.png";
import Israel from "../../Assets/countryFlags/israel.png";
import Italia from "../../Assets/countryFlags/italia.png";
import Jamaica from "../../Assets/countryFlags/jamaica.png";
import Japao from "../../Assets/countryFlags/japao.png";
import Jordania from "../../Assets/countryFlags/jordania.png";
import Kosovo from "../../Assets/countryFlags/kosovo.png";
import Kuwait from "../../Assets/countryFlags/kuwait.png";
import Laos from "../../Assets/countryFlags/laos.png";
import Letonia from "../../Assets/countryFlags/letonia.png";
import Libano from "../../Assets/countryFlags/libano.png";
import Liberia from "../../Assets/countryFlags/liberia.png";
import Libia from "../../Assets/countryFlags/libia.png";
import Listenstaine from "../../Assets/countryFlags/listenstaine.png";
import Lituania from "../../Assets/countryFlags/lituania.png";
import Luxemburgo from "../../Assets/countryFlags/luxemburgo.png";
import Madagascar from "../../Assets/countryFlags/madagascar.png";
import Malasia from "../../Assets/countryFlags/malasia.png";
import Malawi from "../../Assets/countryFlags/malawi.png";
import Mali from "../../Assets/countryFlags/mali.png";
import Malta from "../../Assets/countryFlags/malta.png";
import Marrocos from "../../Assets/countryFlags/marrocos.png";
import Mauritania from "../../Assets/countryFlags/mauritania.png";
import Mexico from "../../Assets/countryFlags/mexico.png";
import Mocambique from "../../Assets/countryFlags/mocambique.png";
import Moldavia from "../../Assets/countryFlags/moldavia.png";
import Monaco from "../../Assets/countryFlags/monaco.png";
import Mongolia from "../../Assets/countryFlags/mongolia.png";
import Montenegro from "../../Assets/countryFlags/montenegro.png";
import Namibia from "../../Assets/countryFlags/namibia.png";
import Nepal from "../../Assets/countryFlags/nepal.png";
import Nicaragua from "../../Assets/countryFlags/nicaragua.png";
import Nigeria from "../../Assets/countryFlags/nigeria.png";
import Niger from "../../Assets/countryFlags/niger.png";
import Noruega from "../../Assets/countryFlags/noruega.png";
import NovaZelandia from "../../Assets/countryFlags/nova-zelandia.png";
import Oma from "../../Assets/countryFlags/oma.png";
import PaisesBaixos from "../../Assets/countryFlags/paises-baixos.png";
import Palestina from "../../Assets/countryFlags/palestina.png";
import Panama from "../../Assets/countryFlags/panama.png";
import PapuaNovaGuine from "../../Assets/countryFlags/papua-nova-guine.png";
import Paquistao from "../../Assets/countryFlags/paquistao.png";
import Paraguai from "../../Assets/countryFlags/paraguai.png";
import Peru from "../../Assets/countryFlags/peru.png";
import Polonia from "../../Assets/countryFlags/polonia.png";
import PortoRico from "../../Assets/countryFlags/porto-rico.png";
import Portugal from "../../Assets/countryFlags/portugal.png";
import Quenia from "../../Assets/countryFlags/quenia.png";
import Quirguistao from "../../Assets/countryFlags/quirguistao.png";
import ReinoUnido from "../../Assets/countryFlags/reino-unido.png";
import RepublicaCentroAfricana from "../../Assets/countryFlags/republica-centro-africana.png";
import RepublicaDominicana from "../../Assets/countryFlags/republica-dominicana.png";
import Romenia from "../../Assets/countryFlags/romenia.png";
import Ruanda from "../../Assets/countryFlags/ruanda.png";
import Russia from "../../Assets/countryFlags/russia.png";
import SaoMarinho from "../../Assets/countryFlags/sao-marinho.png";
import Seicheles from "../../Assets/countryFlags/seicheles.png";
import Senegal from "../../Assets/countryFlags/senegal.png";
import SerraLeoa from "../../Assets/countryFlags/serra-leoa.png";
import Servia from "../../Assets/countryFlags/servia.png";
import Siria from "../../Assets/countryFlags/siria.png";
import Somalia from "../../Assets/countryFlags/somalia.png";
import SriLanka from "../../Assets/countryFlags/sri-lanka.png";
import SudaoDoSul from "../../Assets/countryFlags/sudao-do-sul.png";
import Sudao from "../../Assets/countryFlags/sudao.png";
import Suecia from "../../Assets/countryFlags/suecia.png";
import Suica from "../../Assets/countryFlags/suica.png";
import Suriname from "../../Assets/countryFlags/suriname.png";
import Tailandia from "../../Assets/countryFlags/tailandia.png";
import Tanzania from "../../Assets/countryFlags/tanzania.png";
import TimorLeste from "../../Assets/countryFlags/timor-leste.png";
import TrindadeETobago from "../../Assets/countryFlags/trindade-e-tobago.png";
import Tunisia from "../../Assets/countryFlags/tunisia.png";
import Turcomenistao from "../../Assets/countryFlags/turcomenistao.png";
import Turquia from "../../Assets/countryFlags/turquia.png";
import Ucrania from "../../Assets/countryFlags/ucrania.png";
import Uganda from "../../Assets/countryFlags/uganda.png";
import Uruguai from "../../Assets/countryFlags/uruguai.png";
import Uzbequistao from "../../Assets/countryFlags/uzbequistao.png";
import Venezuela from "../../Assets/countryFlags/venezuela.png";
import Vietna from "../../Assets/countryFlags/vietna.png";
import Zambia from "../../Assets/countryFlags/zambia.png";
import Zimbabue from "../../Assets/countryFlags/zimbabue.png";

const Options = [
  {
    value: "55",
    label: (
      <div className="label">
        <img alt="" src={Brasil} /> Brasil (+55)
      </div>
    ),
  },
  {
    value: "93",
    label: (
      <div className="label">
        <img alt="" src={Afeganistao} /> Afeganistão (+93)
      </div>
    ),
  },
  {
    value: "27",
    label: (
      <div className="label">
        <img alt="" src={AfricaDoSul} /> África do Sul (+27)
      </div>
    ),
  },
  {
    value: "355",
    label: (
      <div className="label">
        <img alt="" src={Albania} /> Albânia (+355)
      </div>
    ),
  },
  {
    value: "49",
    label: (
      <div className="label">
        <img alt="" src={Alemanha} /> Alemanha (+49)
      </div>
    ),
  },
  {
    value: "376",
    label: (
      <div className="label">
        <img alt="" src={Andorra} /> Andorra (+376)
      </div>
    ),
  },
  {
    value: "244",
    label: (
      <div className="label">
        <img alt="" src={Angola} /> Angola (+244)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={AntiguaEBarbuda} /> Antígua e Barbuda (+1)
      </div>
    ),
  },
  {
    value: "966",
    label: (
      <div className="label">
        <img alt="" src={ArabiaSaudita} /> Arábia Saudita (+966)
      </div>
    ),
  },
  {
    value: "213",
    label: (
      <div className="label">
        <img alt="" src={Argelia} /> Argélia (+213)
      </div>
    ),
  },
  {
    value: "54",
    label: (
      <div className="label">
        <img alt="" src={Argentina} /> Argentina (+54)
      </div>
    ),
  },
  {
    value: "374",
    label: (
      <div className="label">
        <img alt="" src={Armenia} /> Armênia (+374)
      </div>
    ),
  },
  {
    value: "297",
    label: (
      <div className="label">
        <img alt="" src={Aruba} /> Aruba (+297)
      </div>
    ),
  },
  {
    value: "61",
    label: (
      <div className="label">
        <img alt="" src={Australia} /> Austrália (+61)
      </div>
    ),
  },
  {
    value: "43",
    label: (
      <div className="label">
        <img alt="" src={Austria} /> Áustria (+43)
      </div>
    ),
  },
  {
    value: "994",
    label: (
      <div className="label">
        <img alt="" src={Azerbaijao} /> Azerbaijão (+994)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Bahamas} /> Bahamas (+1)
      </div>
    ),
  },
  {
    value: "973",
    label: (
      <div className="label">
        <img alt="" src={Bahrein} /> Bahrein (+973)
      </div>
    ),
  },
  {
    value: "880",
    label: (
      <div className="label">
        <img alt="" src={Bangladesh} /> Bangladesh (+880)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Barbados} /> Barbados (+1)
      </div>
    ),
  },
  {
    value: "32",
    label: (
      <div className="label">
        <img alt="" src={Belgica} /> Bélgica (+32)
      </div>
    ),
  },
  {
    value: "501",
    label: (
      <div className="label">
        <img alt="" src={Belize} /> Belize (+501)
      </div>
    ),
  },
  {
    value: "229",
    label: (
      <div className="label">
        <img alt="" src={Benim} /> Benim (+229)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Bermudas} /> Bermudas (+1)
      </div>
    ),
  },
  {
    value: "375",
    label: (
      <div className="label">
        <img alt="" src={Bielorrussia} /> Bielorrússia (+375)
      </div>
    ),
  },
  {
    value: "591",
    label: (
      <div className="label">
        <img alt="" src={Bolivia} /> Bolívia (+591)
      </div>
    ),
  },
  {
    value: "387",
    label: (
      <div className="label">
        <img alt="" src={BosniaEHerzegovina} /> Bósnia e Herzegovina (+387)
      </div>
    ),
  },
  {
    value: "267",
    label: (
      <div className="label">
        <img alt="" src={Botsuana} /> Botswana (+267)
      </div>
    ),
  },
  {
    value: "55",
    label: (
      <div className="label">
        <img alt="" src={Brasil} /> Brasil (+55)
      </div>
    ),
  },
  {
    value: "673",
    label: (
      <div className="label">
        <img alt="" src={Brunei} /> Brunei (+673)
      </div>
    ),
  },
  {
    value: "359",
    label: (
      <div className="label">
        <img alt="" src={Bulgaria} /> Bulgária (+359)
      </div>
    ),
  },
  {
    value: "226",
    label: (
      <div className="label">
        <img alt="" src={BurkinaFaso} /> Burkina Faso (+226)
      </div>
    ),
  },
  {
    value: "257",
    label: (
      <div className="label">
        <img alt="" src={Burundi} /> Burundi (+257)
      </div>
    ),
  },
  {
    value: "975",
    label: (
      <div className="label">
        <img alt="" src={Butao} /> Butão (+975)
      </div>
    ),
  },
  {
    value: "238",
    label: (
      <div className="label">
        <img alt="" src={CaboVerde} /> Cabo Verde (+238)
      </div>
    ),
  },
  {
    value: "237",
    label: (
      <div className="label">
        <img alt="" src={Camaroes} /> Camarões (+237)
      </div>
    ),
  },
  {
    value: "855",
    label: (
      <div className="label">
        <img alt="" src={Camboja} /> Camboja (+855)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Canada} /> Canadá (+1)
      </div>
    ),
  },
  {
    value: "7",
    label: (
      <div className="label">
        <img alt="" src={Cazaquistao} /> Cazaquistão (+7)
      </div>
    ),
  },
  {
    value: "237",
    label: (
      <div className="label">
        <img alt="" src={Chade} /> Chade (+237)
      </div>
    ),
  },
  {
    value: "56",
    label: (
      <div className="label">
        <img alt="" src={Chile} /> Chile (+56)
      </div>
    ),
  },
  {
    value: "86",
    label: (
      <div className="label">
        <img alt="" src={China} /> China (+86)
      </div>
    ),
  },
  {
    value: "357",
    label: (
      <div className="label">
        <img alt="" src={Chipre} /> Chipre (+357)
      </div>
    ),
  },
  {
    value: "57",
    label: (
      <div className="label">
        <img alt="" src={Colombia} /> Colômbia (+57)
      </div>
    ),
  },
  {
    value: "269",
    label: (
      <div className="label">
        <img alt="" src={Comores} /> Comores (+269)
      </div>
    ),
  },
  {
    value: "850",
    label: (
      <div className="label">
        <img alt="" src={CoreiaDoNorte} /> Coreia do Norte (+850)
      </div>
    ),
  },
  {
    value: "82",
    label: (
      <div className="label">
        <img alt="" src={CoreiaDoSul} /> Coreia do Sul (+82)
      </div>
    ),
  },
  {
    value: "225",
    label: (
      <div className="label">
        <img alt="" src={CostaDoMarfim} /> Costa do Marfim (+225)
      </div>
    ),
  },
  {
    value: "506",
    label: (
      <div className="label">
        <img alt="" src={CostaRica} /> Costa Rica (+506)
      </div>
    ),
  },
  {
    value: "385",
    label: (
      <div className="label">
        <img alt="" src={Croacia} /> Croácia (+385)
      </div>
    ),
  },
  {
    value: "53",
    label: (
      <div className="label">
        <img alt="" src={Cuba} /> Cuba (+53)
      </div>
    ),
  },
  {
    value: "45",
    label: (
      <div className="label">
        <img alt="" src={Dinamarca} /> Dinamarca (+45)
      </div>
    ),
  },
  {
    value: "253",
    label: (
      <div className="label">
        <img alt="" src={Djibuti} /> Djibuti (+253)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Dominica} /> Dominica (+1)
      </div>
    ),
  },
  {
    value: "20",
    label: (
      <div className="label">
        <img alt="" src={Egito} /> Egipto (+20)
      </div>
    ),
  },
  {
    value: "503",
    label: (
      <div className="label">
        <img alt="" src={ElSalvador} /> El Salvador (+503)
      </div>
    ),
  },
  {
    value: "971",
    label: (
      <div className="label">
        <img alt="" src={EmiradosArabesUnidos} /> Emirados Árabes Unidos (+971)
      </div>
    ),
  },
  {
    value: "593",
    label: (
      <div className="label">
        <img alt="" src={Equador} /> Equador (+593)
      </div>
    ),
  },
  {
    value: "421",
    label: (
      <div className="label">
        <img alt="" src={Eslovaquia} /> Eslováquia (+421)
      </div>
    ),
  },
  {
    value: "386",
    label: (
      <div className="label">
        <img alt="" src={Eslovenia} /> Eslovénia (+386)
      </div>
    ),
  },
  {
    value: "34",
    label: (
      <div className="label">
        <img alt="" src={Espanha} /> Espanha (+34)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={EstadosUnidos} /> Estados Unidos (+1)
      </div>
    ),
  },
  {
    value: "372",
    label: (
      <div className="label">
        <img alt="" src={Estonia} /> Estónia (+372)
      </div>
    ),
  },
  {
    value: "251",
    label: (
      <div className="label">
        <img alt="" src={Etiopia} /> Etiópia (+251)
      </div>
    ),
  },
  {
    value: "679",
    label: (
      <div className="label">
        <img alt="" src={Fiji} /> Fiji (+679)
      </div>
    ),
  },
  {
    value: "63",
    label: (
      <div className="label">
        <img alt="" src={Filipinas} /> Filipinas (+63)
      </div>
    ),
  },
  {
    value: "358",
    label: (
      <div className="label">
        <img alt="" src={Finlandia} /> Finlândia (+358)
      </div>
    ),
  },
  {
    value: "33",
    label: (
      <div className="label">
        <img alt="" src={Franca} /> França (+33)
      </div>
    ),
  },
  {
    value: "241",
    label: (
      <div className="label">
        <img alt="" src={Gabao} /> Gabão (+241)
      </div>
    ),
  },
  {
    value: "220",
    label: (
      <div className="label">
        <img alt="" src={Gambia} /> Gâmbia (+220)
      </div>
    ),
  },
  {
    value: "233",
    label: (
      <div className="label">
        <img alt="" src={Gana} /> Gana (+233)
      </div>
    ),
  },
  {
    value: "995",
    label: (
      <div className="label">
        <img alt="" src={Georgia} /> Geórgia (+995)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Granada} /> Granada (+1)
      </div>
    ),
  },
  {
    value: "30",
    label: (
      <div className="label">
        <img alt="" src={Grecia} /> Grécia (+30)
      </div>
    ),
  },
  {
    value: "502",
    label: (
      <div className="label">
        <img alt="" src={Guatemala} /> Guatemala (+502)
      </div>
    ),
  },
  {
    value: "592",
    label: (
      <div className="label">
        <img alt="" src={Guiana} /> Guiana (+592)
      </div>
    ),
  },
  {
    value: "245",
    label: (
      <div className="label">
        <img alt="" src={GuineBissau} /> Guiné-Bissau (+245)
      </div>
    ),
  },
  {
    value: "240",
    label: (
      <div className="label">
        <img alt="" src={GuineEquatorial} /> Guiné Equatorial (+240)
      </div>
    ),
  },
  {
    value: "224",
    label: (
      <div className="label">
        <img alt="" src={Guine} /> Guiné (+224)
      </div>
    ),
  },
  {
    value: "509",
    label: (
      <div className="label">
        <img alt="" src={Haiti} /> Haiti (+509)
      </div>
    ),
  },
  {
    value: "504",
    label: (
      <div className="label">
        <img alt="" src={Honduras} /> Honduras (+504)
      </div>
    ),
  },
  {
    value: "852",
    label: (
      <div className="label">
        <img alt="" src={HongKong} /> Hong Kong (+852)
      </div>
    ),
  },
  {
    value: "36",
    label: (
      <div className="label">
        <img alt="" src={Hungria} /> Hungria (+36)
      </div>
    ),
  },
  {
    value: "967",
    label: (
      <div className="label">
        <img alt="" src={Iemen} /> Iêmen (+967)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={IlhasCayman} /> Ilhas Cayman (+1)
      </div>
    ),
  },
  {
    value: "298",
    label: (
      <div className="label">
        <img alt="" src={IlhasFaroe} /> Ilhas Féroe (+298)
      </div>
    ),
  },
  {
    value: "91",
    label: (
      <div className="label">
        <img alt="" src={India} /> Índia (+91)
      </div>
    ),
  },
  {
    value: "62",
    label: (
      <div className="label">
        <img alt="" src={Indonesia} /> Indonésia (+62)
      </div>
    ),
  },
  {
    value: "98",
    label: (
      <div className="label">
        <img alt="" src={Ira} /> Irã (+98)
      </div>
    ),
  },
  {
    value: "964",
    label: (
      <div className="label">
        <img alt="" src={Iraque} /> Iraque (+964)
      </div>
    ),
  },
  {
    value: "353",
    label: (
      <div className="label">
        <img alt="" src={Irlanda} /> Irlanda (+353)
      </div>
    ),
  },
  {
    value: "354",
    label: (
      <div className="label">
        <img alt="" src={Islandia} /> Islândia (+354)
      </div>
    ),
  },
  {
    value: "972",
    label: (
      <div className="label">
        <img alt="" src={Israel} /> Israel (+972)
      </div>
    ),
  },
  {
    value: "39",
    label: (
      <div className="label">
        <img alt="" src={Italia} /> Itália (+39)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={Jamaica} /> Jamaica (+1)
      </div>
    ),
  },
  {
    value: "81",
    label: (
      <div className="label">
        <img alt="" src={Japao} /> Japão (+81)
      </div>
    ),
  },
  {
    value: "962",
    label: (
      <div className="label">
        <img alt="" src={Jordania} /> Jordânia (+962)
      </div>
    ),
  },
  {
    value: "383",
    label: (
      <div className="label">
        <img alt="" src={Kosovo} /> Kosovo (+383)
      </div>
    ),
  },
  {
    value: "965",
    label: (
      <div className="label">
        <img alt="" src={Kuwait} /> Kuwait (+965)
      </div>
    ),
  },
  {
    value: "856",
    label: (
      <div className="label">
        <img alt="" src={Laos} /> Laos (+856)
      </div>
    ),
  },
  {
    value: "371",
    label: (
      <div className="label">
        <img alt="" src={Letonia} /> Letônia (+371)
      </div>
    ),
  },
  {
    value: "961",
    label: (
      <div className="label">
        <img alt="" src={Libano} /> Líbano (+961)
      </div>
    ),
  },
  {
    value: "231",
    label: (
      <div className="label">
        <img alt="" src={Liberia} /> Libéria (+231)
      </div>
    ),
  },
  {
    value: "218",
    label: (
      <div className="label">
        <img alt="" src={Libia} /> Líbia (+218)
      </div>
    ),
  },
  {
    value: "423",
    label: (
      <div className="label">
        <img alt="" src={Listenstaine} /> Liechtenstein (+423)
      </div>
    ),
  },
  {
    value: "370",
    label: (
      <div className="label">
        <img alt="" src={Lituania} /> Lituânia (+370)
      </div>
    ),
  },
  {
    value: "352",
    label: (
      <div className="label">
        <img alt="" src={Luxemburgo} /> Luxemburgo (+352)
      </div>
    ),
  },
  {
    value: "261",
    label: (
      <div className="label">
        <img alt="" src={Madagascar} /> Madagascar (+261)
      </div>
    ),
  },
  {
    value: "60",
    label: (
      <div className="label">
        <img alt="" src={Malasia} /> Malásia (+60)
      </div>
    ),
  },
  {
    value: "265",
    label: (
      <div className="label">
        <img alt="" src={Malawi} /> Malawi (+265)
      </div>
    ),
  },
  {
    value: "223",
    label: (
      <div className="label">
        <img alt="" src={Mali} /> Mali (+223)
      </div>
    ),
  },
  {
    value: "356",
    label: (
      <div className="label">
        <img alt="" src={Malta} /> Malta (+356)
      </div>
    ),
  },
  {
    value: "212",
    label: (
      <div className="label">
        <img alt="" src={Marrocos} /> Marrocos (+212)
      </div>
    ),
  },
  {
    value: "222",
    label: (
      <div className="label">
        <img alt="" src={Mauritania} /> Mauritânia (+222)
      </div>
    ),
  },
  {
    value: "52",
    label: (
      <div className="label">
        <img alt="" src={Mexico} /> México (+52)
      </div>
    ),
  },
  {
    value: "258",
    label: (
      <div className="label">
        <img alt="" src={Mocambique} /> Moçambique (+258)
      </div>
    ),
  },
  {
    value: "373",
    label: (
      <div className="label">
        <img alt="" src={Moldavia} /> Moldávia (+373)
      </div>
    ),
  },
  {
    value: "377",
    label: (
      <div className="label">
        <img alt="" src={Monaco} /> Mônaco (+377)
      </div>
    ),
  },
  {
    value: "976",
    label: (
      <div className="label">
        <img alt="" src={Mongolia} /> Mongólia (+976)
      </div>
    ),
  },
  {
    value: "382",
    label: (
      <div className="label">
        <img alt="" src={Montenegro} /> Montenegro (+382)
      </div>
    ),
  },
  {
    value: "264",
    label: (
      <div className="label">
        <img alt="" src={Namibia} /> Namíbia (+264)
      </div>
    ),
  },
  {
    value: "977",
    label: (
      <div className="label">
        <img alt="" src={Nepal} /> Nepal (+977)
      </div>
    ),
  },
  {
    value: "505",
    label: (
      <div className="label">
        <img alt="" src={Nicaragua} /> Nicarágua (+505)
      </div>
    ),
  },
  {
    value: "234",
    label: (
      <div className="label">
        <img alt="" src={Nigeria} /> Nigéria (+234)
      </div>
    ),
  },
  {
    value: "227",
    label: (
      <div className="label">
        <img alt="" src={Niger} /> Níger (+227)
      </div>
    ),
  },
  {
    value: "47",
    label: (
      <div className="label">
        <img alt="" src={Noruega} /> Noruega (+47)
      </div>
    ),
  },
  {
    value: "64",
    label: (
      <div className="label">
        <img alt="" src={NovaZelandia} /> Nova Zelândia (+64)
      </div>
    ),
  },
  {
    value: "968",
    label: (
      <div className="label">
        <img alt="" src={Oma} /> Omã (+968)
      </div>
    ),
  },
  {
    value: "31",
    label: (
      <div className="label">
        <img alt="" src={PaisesBaixos} /> Países Baixos (+31)
      </div>
    ),
  },
  {
    value: "970",
    label: (
      <div className="label">
        <img alt="" src={Palestina} /> Palestina (+970)
      </div>
    ),
  },
  {
    value: "507",
    label: (
      <div className="label">
        <img alt="" src={Panama} /> Panamá (+507)
      </div>
    ),
  },
  {
    value: "675",
    label: (
      <div className="label">
        <img alt="" src={PapuaNovaGuine} /> Papua-Nova Guiné (+675)
      </div>
    ),
  },
  {
    value: "92",
    label: (
      <div className="label">
        <img alt="" src={Paquistao} /> Paquistão (+92)
      </div>
    ),
  },
  {
    value: "595",
    label: (
      <div className="label">
        <img alt="" src={Paraguai} /> Paraguai (+595)
      </div>
    ),
  },
  {
    value: "51",
    label: (
      <div className="label">
        <img alt="" src={Peru} /> Peru (+51)
      </div>
    ),
  },
  {
    value: "48",
    label: (
      <div className="label">
        <img alt="" src={Polonia} /> Polônia (+48)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={PortoRico} /> Porto Rico (+1)
      </div>
    ),
  },
  {
    value: "351",
    label: (
      <div className="label">
        <img alt="" src={Portugal} /> Portugal (+351)
      </div>
    ),
  },
  {
    value: "254",
    label: (
      <div className="label">
        <img alt="" src={Quenia} /> Quênia (+254)
      </div>
    ),
  },
  {
    value: "996",
    label: (
      <div className="label">
        <img alt="" src={Quirguistao} /> Quirguistão (+996)
      </div>
    ),
  },
  {
    value: "44",
    label: (
      <div className="label">
        <img alt="" src={ReinoUnido} /> Reino Unido (+44)
      </div>
    ),
  },
  {
    value: "236",
    label: (
      <div className="label">
        <img alt="" src={RepublicaCentroAfricana} /> República Centro-Africana
        (+236)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={RepublicaDominicana} /> República Dominicana (+1)
      </div>
    ),
  },
  {
    value: "40",
    label: (
      <div className="label">
        <img alt="" src={Romenia} /> Romênia (+40)
      </div>
    ),
  },
  {
    value: "250",
    label: (
      <div className="label">
        <img alt="" src={Ruanda} /> Ruanda (+250)
      </div>
    ),
  },
  {
    value: "7",
    label: (
      <div className="label">
        <img alt="" src={Russia} /> Rússia (+7)
      </div>
    ),
  },
  {
    value: "378",
    label: (
      <div className="label">
        <img alt="" src={SaoMarinho} /> São Marinho (+378)
      </div>
    ),
  },
  {
    value: "248",
    label: (
      <div className="label">
        <img alt="" src={Seicheles} /> Seicheles (+248)
      </div>
    ),
  },
  {
    value: "221",
    label: (
      <div className="label">
        <img alt="" src={Senegal} /> Senegal (+221)
      </div>
    ),
  },
  {
    value: "232",
    label: (
      <div className="label">
        <img alt="" src={SerraLeoa} /> Serra Leoa (+232)
      </div>
    ),
  },
  {
    value: "381",
    label: (
      <div className="label">
        <img alt="" src={Servia} /> Sérvia (+381)
      </div>
    ),
  },
  {
    value: "963",
    label: (
      <div className="label">
        <img alt="" src={Siria} /> Síria (+963)
      </div>
    ),
  },
  {
    value: "252",
    label: (
      <div className="label">
        <img alt="" src={Somalia} /> Somália (+252)
      </div>
    ),
  },
  {
    value: "94",
    label: (
      <div className="label">
        <img alt="" src={SriLanka} /> Sri Lanka (+94)
      </div>
    ),
  },
  {
    value: "211",
    label: (
      <div className="label">
        <img alt="" src={SudaoDoSul} /> Sudão do Sul (+211)
      </div>
    ),
  },
  {
    value: "249",
    label: (
      <div className="label">
        <img alt="" src={Sudao} /> Sudão (+249)
      </div>
    ),
  },
  {
    value: "46",
    label: (
      <div className="label">
        <img alt="" src={Suecia} /> Suécia (+46)
      </div>
    ),
  },
  {
    value: "41",
    label: (
      <div className="label">
        <img alt="" src={Suica} /> Suíça (+41)
      </div>
    ),
  },
  {
    value: "597",
    label: (
      <div className="label">
        <img alt="" src={Suriname} /> Suriname (+597)
      </div>
    ),
  },
  {
    value: "66",
    label: (
      <div className="label">
        <img alt="" src={Tailandia} /> Tailândia (+66)
      </div>
    ),
  },
  {
    value: "255",
    label: (
      <div className="label">
        <img alt="" src={Tanzania} /> Tanzânia (+255)
      </div>
    ),
  },
  {
    value: "670",
    label: (
      <div className="label">
        <img alt="" src={TimorLeste} /> Timor-Leste (+670)
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="label">
        <img alt="" src={TrindadeETobago} /> Trinidad e Tobago (+1)
      </div>
    ),
  },
  {
    value: "216",
    label: (
      <div className="label">
        <img alt="" src={Tunisia} /> Tunísia (+216)
      </div>
    ),
  },
  {
    value: "993",
    label: (
      <div className="label">
        <img alt="" src={Turcomenistao} /> Turquemenistão (+993)
      </div>
    ),
  },
  {
    value: "90",
    label: (
      <div className="label">
        <img alt="" src={Turquia} /> Turquia (+90)
      </div>
    ),
  },
  {
    value: "380",
    label: (
      <div className="label">
        <img alt="" src={Ucrania} /> Ucrânia (+380)
      </div>
    ),
  },
  {
    value: "256",
    label: (
      <div className="label">
        <img alt="" src={Uganda} /> Uganda (+256)
      </div>
    ),
  },
  {
    value: "598",
    label: (
      <div className="label">
        <img alt="" src={Uruguai} /> Uruguai (+598)
      </div>
    ),
  },
  {
    value: "998",
    label: (
      <div className="label">
        <img alt="" src={Uzbequistao} /> Uzbequistão (+998)
      </div>
    ),
  },
  {
    value: "58",
    label: (
      <div className="label">
        <img alt="" src={Venezuela} /> Venezuela (+58)
      </div>
    ),
  },
  {
    value: "84",
    label: (
      <div className="label">
        <img alt="" src={Vietna} /> Vietnã (+84)
      </div>
    ),
  },
  {
    value: "260",
    label: (
      <div className="label">
        <img alt="" src={Zambia} /> Zâmbia (+260)
      </div>
    ),
  },
  {
    value: "263",
    label: (
      <div className="label">
        <img alt="" src={Zimbabue} /> Zimbábue (+263)
      </div>
    ),
  },
];

const FormatPhone = (phone, setPhone) => {
  phone = phone.replace(/\D/g, "");
  if (phone.length > 0 && phone.length < 3) {
    phone = "(" + phone.substring(0, 2);
  } else if (phone.length > 2 && phone.length < 7) {
    phone =
      "(" + phone.substring(0, 2) + ") " + phone.substring(2, phone.length);
  } else if (phone.length > 6 && phone.length < 11) {
    phone =
      "(" +
      phone.substring(0, 2) +
      ") " +
      phone.substring(2, 6) +
      "-" +
      phone.substring(6, phone.length);
  } else if (phone.length === 11) {
    phone =
      "(" +
      phone.substring(0, 2) +
      ") " +
      phone.substring(2, 7) +
      "-" +
      phone.substring(7, phone.length);
  } else if (phone.length > 11) {
    phone =
      "(" +
      phone.substring(0, 2) +
      ") " +
      phone.substring(2, 7) +
      "-" +
      phone.substring(7, 11);
  }
  setPhone(phone);
};

export { Options, FormatPhone };
