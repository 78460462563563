import { useEffect } from "react";
import { useParams } from "react-router-dom";

function Redirect() {
  let { phone } = useParams();

  useEffect(() => {
    let button = document.getElementById("open");
    button.click();
  });

  const handleClickWhatsApp = () => {
    if (phone !== "") {
      window.open(`whatsapp://send?phone=${phone}`);
    }
  };

  return (
    <button id="open" onClick={() => handleClickWhatsApp()}>
      open
    </button>
  );
}

export default Redirect;
