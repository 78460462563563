import React, { useState } from "react";
import "./style.scss";
import Brasil from "../../Assets/countryFlags/brasil.png";
import Coffee from "../../Assets/coffee.png";
import Whatsapp from "../../Assets/whatsapp.png";
import { FormatPhone, Options } from "./Options.js";
import Select from "react-select";
import QRCode from "react-qr-code";
function Main() {
  const [ddi, setDdi] = useState({
    value: "55",
    label: (
      <div className="label">
        <img alt="" src={Brasil} /> Brasil (+55)
      </div>
    ),
  });
  const [phone, setPhone] = useState("");

  const handleClickWhatsApp = () => {
    if (phone !== "") {
      window.open(
        `whatsapp://send?phone=${ddi.value}${phone.replace(/\D/g, "")}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const handleClickWhatsWeb = () => {
    if (phone !== "") {
      window.open(
        `https://web.whatsapp.com/send?phone=${ddi.value}${phone.replace(
          /\D/g,
          ""
        )}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className="body">
      <header>
        <div>
          <img src={Whatsapp} alt="whatsapp icon" />
          <h1>Zapinho</h1>
        </div>
      </header>
      <main>
        <article>
          <p>
            Uma forma simples de entrar em contato com uma pessoa, loja ou
            empresa sem adiciona-la na agenda.
          </p>
        </article>
        <h3>Escreva o número abaixo</h3>
        <div className="phoneArea">
          <div className="select">
            <Select
              isSearchable={false}
              options={Options}
              onChange={(option) => setDdi(option)}
              value={ddi}
            />
          </div>
          <input
            placeholder="(31) 99999-9999"
            onChange={(event) => FormatPhone(event.target.value, setPhone)}
            type="text"
            value={phone}
          />
        </div>
        <h3> Enviar mensagem por:</h3>
        <div className="buttonArea">
          <div className="qrArea">
            <span>QR Whatsapp Aplicativo</span>
            <div>
              <QRCode
                value={`https://zapinho.com/redirect/${
                  ddi.value
                }${phone.replace(/\D/g, "")}`}
              />
            </div>
            <button className="button" onClick={() => handleClickWhatsApp()}>
              Aplicativo do Whatsapp
            </button>
          </div>
          <div className="qrArea">
            <span>QR Whatsapp WEB</span>
            <div>
              <QRCode
                value={`https://web.whatsapp.com/send?phone=${
                  ddi.value
                }${phone.replace(/\D/g, "")}`}
              />
            </div>
            <button className="button" onClick={() => handleClickWhatsWeb()}>
              Whatsapp WEB
            </button>
          </div>
        </div>
        <div>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7146448574148879"
            crossOrigin="anonymous"
          ></script>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-7146448574148879"
            data-ad-slot="7736097860"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div>
      </main>
      <footer>
        <div className="buttonsArea">
          <p>
            Desfrutou da experiência com nosso aplicativo? Que tal me presentear
            com um cafezinho? <br />A sua generosidade contribuiria para apoiar
            o desenvolvimento contínuo do nosso serviço. <br /> Agradecemos
            imensamente pela sua consideração.
          </p>
          <a
            className="button buttonDonate"
            href="https://www.paypal.com/donate/?business=QGZ4Q9JX5KS5W&no_recurring=0&currency_code=BRL"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Coffee} alt="coffee mug" />
            Clique aqui para enviar um café
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Main;
